import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  return (
    <div>
      <nav className="bg-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4">
                  {/* <Link to="/" onClick={()=>{setSelectedTab("dday")}}>
                    <div
                      className={` hover:bg-gray-700 text-white px-3 py-2 rounded-md text-sm font-medium ${selectedTab==="dday"&&"bg-pink"}` }
                    >
                      Home
                    </div>
                  </Link>
                  <Link to="/dday" onClick={()=>{setSelectedTab("dday")}}>
                    <div
                      className={` hover:bg-gray-700 text-white px-3 py-2 rounded-md text-sm font-medium ${selectedTab==="dday"&&"bg-pink"}` }
                    >
                      D-day
                    </div>
                  </Link> */}
                  <Link to="/rsvp">
                    <div className=" hover:bg-gray-700 text-white px-3 py-2 rounded-md text-sm font-medium">
                      RSVP
                    </div>
                  </Link>
                  <Link
                    to="/infos"
                    onClick={() => {
                      setSelectedTab("infos");
                    }}
                  >
                    <div
                      className={` hover:bg-gray-700 text-white px-3 py-2 rounded-md text-sm font-medium ${
                        selectedTab === "dday" && "bg-pink"
                      }`}
                    >
                      Infos pratiques
                    </div>
                  </Link>

                  <Link
                    to="/story"
                    onClick={() => {
                      setSelectedTab("story");
                    }}
                  >
                    <div
                      className={` hover:bg-gray-700 text-white px-3 py-2 rounded-md text-sm font-medium ${
                        selectedTab === "story" && "bg-pink"
                      }`}
                    >
                      Our Story
                    </div>
                  </Link>

                  <a href="https://www.millemercismariage.com/acande4life/liste.html">
                    <div
                      className={` hover:bg-gray-700 text-white px-3 py-2 rounded-md text-sm font-medium ${
                        selectedTab === "dday" && "bg-pink"
                      }`}
                    >
                      Liste de mariage
                    </div>
                  </a>
                  {/* <Link to="/form">
                    <div
                      className=" hover:bg-gray-700 text-white px-3 py-2 rounded-md text-sm font-medium"
                    >
                       Form
                    </div>
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="md:hidden" id="mobile-menu">
              <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {/* <Link to="/">
                  <div className="hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium">
                    Home
                  </div>
                </Link>
                <Link to="/dday">
                  <div className="hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium">
                    D-day
                  </div>
                </Link> */}

                <Link to="/rsvp">
                  <div className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                    RSVP
                  </div>
                </Link>

                <Link to="/infos">
                  <div className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                    Infos pratiques
                  </div>
                </Link>

                <Link to="/story">
                  <div className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                    Our story
                  </div>
                </Link>
                {/*                 
                <Link to="/form">
                  <div
                    className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  >
                    Form
                  </div>
                </Link> */}
              </div>
            </div>
          )}
        </Transition>
      </nav>
    </div>
  );
}

export default Navbar;
