import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Annoncement from "./components/Annoucement";
import Infos from "./components/Infos";
import MainLayout from "./components/MainLayout";
import Story from "./components/Story";

const RedirectToRVP = () => {
  return (
    <iframe
      title="rsvpredirect"
      className="w-full h-screen"
      src="https://tally.so/r/w2j7Wg"
    ></iframe>
  );
};
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        {/* <Route path="dday" element={<Dday />} />  */}
        <Route element={<MainLayout />}>
          <Route path="story" element={<Story />} />
          <Route path="infos" element={<Infos />} />
        </Route>
        <Route path="rsvp" element={<RedirectToRVP />}></Route>
        {/* <Route path="ubaye" element={<Ubaye/>} />  */}
        {/* <Route path="form" element={<TallyForm/>} />  */}
        <Route path="announcement" element={<Annoncement />} />
        <Route path="*" element={<Navigate to="/announcement" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
