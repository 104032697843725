function Infos() {
  return (
    <div className="min-h-full bg-yellow px-4 lg:px-8 p-4 space-y-4 ">
      <h2 className="text-blue font-bold">Infos pratiques</h2>
      <div className="space-y-2">
        <h3 className="text-blue font-bold">Venir à Barcelonnette</h3>
        <ul>
          <li>
            <span className=" text-2xl">🚄 🚌</span> Train Paris vers Gap (soit
            direct en train de nuit ou avec un changement à Grenoble ou Valence
            en journée) +{" "}
            <a
              href="https://www.autocars-scal.fr/sites/default/files/ligne/horaire-pdf/ZOULER_Ligne30_GAP_BARCELONNETTE_2022.pdf"
              className="underline text-blue"
            >
              navette de la SCAL
            </a>
          </li>
          <li>
            <span className=" text-2xl">🚄 🚗</span> Train Paris vers Aix +
            location de voiture à Aix en Provence
          </li>
        </ul>
      </div>
      <div className="space-y-2">
        <h3 className="text-blue font-bold">Séjourner à Barcelonnette</h3>

        <ul>
          <li>
            <span className=" text-2xl">🏨</span>{" "}
            <a
              href="https://www.azteca-hotel.fr/fr/"
              className="underline text-blue"
            >
              Hotel Azteca{" "}
            </a>
            : 100€ à 200€/nuit (dire que vous venez pour notre mariage, l'hôtel
            est préservé)
          </li>
          <li>
            <span className=" text-2xl">🏨</span>{" "}
            <a href="https://villapuebla.com/" className="underline text-blue">
              Villa Puebla{" "}
            </a>
            : 150€ à 200 €/nuit
          </li>
          <li>
            <span className=" text-2xl">🏨</span> Hotel de la Placette : 75€ à
            130€/ nuit{" "}
          </li>
          <li>
            <span className=" text-2xl">🛋️</span> Autre dans Barcelonnette:
            Hotel du cheval blanc, Le Grand Hôtel, La Grande Epervière, booking
            et AirBnb
          </li>
          <li>
            <span className=" text-2xl">🛋️</span> Autre en dehors de
            Barcelonnette: Le Passe Montagne, Auberge du Bachelard, booking et
            AirBnb
          </li>
        </ul>
      </div>
      <div className="space-y-2">
        <h3 className="text-blue font-bold">
          Où faire ses courses à Barcelonnette:
        </h3>

        <ul>
          <li>
            <span className=" text-2xl">🧀</span>{" "}
            <a
              href="https://www.google.com/maps/place/Coop%C3%A9rative+Laiti%C3%A8re+de+la+Vall%C3%A9e+de+l'Ubaye+-+Fromagerie+Le+Chambeyron/@44.3841866,6.6475223,17z/data=!3m1!4b1!4m5!3m4!1s0x12cc9186108b4553:0xff19443053f8c56b!8m2!3d44.384175!4d6.6497082"
              className="underline text-blue"
            >
              Coopérative Laitière de l’Ubaye
            </a>{" "}
            pour les délicieux fromages
          </li>
          <li>
            <span className=" text-2xl">🥬</span>{" "}
            <a
              href="https://www.google.com/maps/place/20+Pl.+Aim%C3%A9+Gassier,+04400+Barcelonnette/@44.3863076,6.6512369,18z/data=!4m5!3m4!1s0x12cc919a9559e285:0x16446ca53a4f06d1!8m2!3d44.3861303!4d6.6517956"
              className="underline text-blue"
            >
              Le marché le samedi matin
            </a>{" "}
            pour tous les produits régionaux
          </li>
          <li>
            <span className=" text-2xl">🥫</span>{" "}
            <a
              href="https://www.google.com/maps/place/Fruits+L%C3%A9gumes+Epicerie+LE+CHALET+GOURMAND+Barcelonnette/@44.3883888,6.6628442,15z/data=!4m5!3m4!1s0x0:0xd59cc84f8da641eb!8m2!3d44.3883888!4d6.6628442"
              className="underline text-blue"
            >
              Le Chalet Gourmand
            </a>{" "}
            pour les produits d'épicerie et fruits et légumes directement venus
            d’Italie
          </li>
          <li>
            <span className=" text-2xl">🍰</span>{" "}
            <a
              href="https://www.google.com/maps/place/MAISON+PADIOU/@44.3883888,6.6596255,15z/data=!4m5!3m4!1s0x0:0xaaeb322612c8920!8m2!3d44.3868095!4d6.6509354"
              className="underline text-blue"
            >
              Maison Padiou{" "}
            </a>
            pour une glace ou une pâtisserie
          </li>
        </ul>
      </div>
      <div className="space-y-2">
        <h3 className="text-blue font-bold">
          Où boire un verre à Barcelonnette:
        </h3>

        <ul>
          <li>
            <span className=" text-2xl">🍷</span>{" "}
            <a
              href="https://www.google.com/maps/place/%C3%94+tandem/@44.3873543,6.6523212,17z/data=!3m1!4b1!4m5!3m4!1s0x12cc91f132772793:0x3e999163287202d1!8m2!3d44.3873543!4d6.6523212"
              className="underline text-blue"
            >
              Ô Tandem{" "}
            </a>
            : bar branché de la rue Manuel (très bien pour dîner également)
          </li>
          <li>
            <span className=" text-2xl">🍺</span>{" "}
            <a
              href="https://www.google.com/maps/place/Choucas+Bar/@44.3875183,6.6496604,17z/data=!4m8!3m7!1s0x0:0x723ff624e63b72d3!5m2!4m1!1i2!8m2!3d44.3877122!4d6.6528285"
              className="underline text-blue"
            >
              Le Choucas
            </a>
            : l’institution de la ville, parfait pour le café le matin ou un
            plat rapide le midi
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Infos;
