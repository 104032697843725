import { useTranslation } from "react-i18next";
//@ts-ignore
import DeviceOrientation, { Orientation } from "react-screen-orientation";
import { useAtom } from "jotai";
import { atomWithHash } from "jotai/utils";
import i18n from "../i18n";
import { useEffect } from "react";

interface Content {
  line1: string;
  line2: string;
  bgColor?: string;
  bgImage?: string;
  textColor?: string;
  bgText?: boolean;
}
const useReleaseContent = () => {
  const { t } = useTranslation();
  const content = [
    {
      line1: t("release.module1.line1"),
      line2: t("release.module1.line2"),
      bgImage: "bg-roburent",
    } as Content,
    {
      line1: t("release.module2.line1"),
      line2: t("release.module2.line2"),
      bgColor: "pink",
    } as Content,
    {
      line1: t("release.module3.line1"),
      line2: t("release.module3.line2"),
      bgImage: "bg-alpi_crete",
      bgText: true,
    } as Content,
    {
      line1: t("release.module4.line1"),
      line2: t("release.module4.line2"),
      bgColor: "yellow",
    } as Content,
    {
      line1: t("release.module5.line1"),
      line2: t("release.module5.line2"),
      bgImage: "bg-barcelo_alt",
    } as Content,
    {
      line1: t("release.module6.line1"),
      line2: t("release.module6.line2"),
      bgColor: "green",
    } as Content,
    {
      line1: t("release.module7.line1"),
      line2: t("release.module7.line2"),
      bgImage: "bg-rando",
    } as Content,
    {
      line1: t("release.module8.line1"),
      line2: t("release.module8.line2"),
      bgImage: "bg-bike",
      bgText: true,
    } as Content,
    {
      line1: t("release.module9.line1"),
      line2: t("release.module9.line2"),
      bgColor: "blue",
    } as Content,
    {
      line1: t("release.module10.line1"),
      line2: t("release.module10.line2"),
      bgImage: "bg-san_francisco",
    } as Content,
    {
      line1: t("release.module11.line1"),
      line2: t("release.module11.line2"),
      bgColor: "yellow",
    } as Content,
    {
      line1: t("release.module12.line1"),
      line2: t("release.module12.line2"),
      bgImage: "bg-jump",
      bgText: true,
    } as Content,
  ];

  return content;
};

const nameAtom = atomWithHash("name", "");

const Annoncement = () => {
  const [name] = useAtom(nameAtom);
  const { t } = useTranslation();
  const content = useReleaseContent();

  useEffect(() => {
    //@ts-ignore
    Tally.loadEmbeds();
  }, []);
  const lang = i18n.language;
  const switchLang = () => {
    i18n.changeLanguage(lang === "fr" ? "en" : "fr");
  };
  return (
    <>
      <DeviceOrientation lockOrientation={"landscape"}>
        {/* Will only be in DOM in landscape */}
        <Orientation orientation="landscape" alwaysRender={false}>
          <div className="w-full flex fixed p-4 justify-end">
            <button
              className=" font-bold text-white uppercase text-3xl"
              onClick={switchLang}
            >
              {" "}
              {lang === "fr" ? <div> 🇺🇸</div> : <div> 🇫🇷</div>}
            </button>
          </div>
          <div>
            <div className="flex flex-col items-center justify-center bg-right-bottom bg-fullWidth h-screen px-[50px]  text-white bg-fixed bg-blue">
              <h1 className="text-center font-extrabold text-white">
                {t("release.intro.hi")} {name},
              </h1>
              <h1 className="text-center">{t("release.intro.scroll")}</h1>
            </div>
          </div>
          {content.map((mod) => (
            <div>
              <div
                className={`flex items-center justify-center min-h-screen lg:py-[500px] px-[50px] lg:px-[150px] ${
                  mod.bgColor && `bg-${mod.bgColor}`
                } ${
                  mod.bgImage &&
                  `bg-fullWidth bg-no-repeat bg-center lg:bg-fixed ${mod.bgImage}`
                }
                `}
              >
                <div
                  className={`text-center space-y-10 ${
                    mod.bgText && "bg-black/20 "
                  }`}
                >
                  <h1
                    className={`font-extrabold xl:text-6xl ${
                      mod.textColor ? `text-${mod.textColor}` : "text-white"
                    }`}
                  >
                    {mod.line1}
                  </h1>
                  <h2
                    className={`font-extrabold xl:text-4xl ${
                      mod.textColor ? `text-${mod.textColor}` : "text-white"
                    }`}
                  >
                    {mod.line2}
                  </h2>
                </div>
              </div>
            </div>
          ))}
          <div>
            <div className="space-y-4 flex flex-col justify-center bg-right-bottom bg-fullWidth lg:min-h-screen px-[50px] py-4 lg:px-[150px] text-white bg-fixed bg-blue">
              <h1 className="text-center font-extrabold text-white">
                {t("release.final.title")}
              </h1>
              <h2
                className=" text-2xl
               text-justify"
              >
                {t("release.final.desc")}
                <br />
                <br />
                {t("release.final.form_request")}
              </h2>
              <a
                className="underline text-yellow"
                href={"https://tally.so/r/wgDEv4"}
              >
                {" "}
                Please click here if the form does not display correctly
              </a>
              <div className="w-full lg:w-96 self-center">
                <iframe
                  data-tally-src="https://tally.so/embed/wgDEv4?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
                  width="100%"
                  height="284"
                  title="Test short"
                ></iframe>
              </div>

              <h1 className="text-center font-handwriting text-7xl pb-2">
                Anne-Claire et Etienne
              </h1>
            </div>
          </div>
        </Orientation>
        {/* Will stay in DOM, but is only visible in portrait */}
        <Orientation orientation="portrait" alwaysRender={false}>
          <div className="min h-screen w-screen flex flex-col items-center justify-center bg-blue p-10">
            <h1 className="text-center text-white">
              Please <b>rotate</b> your device
            </h1>
            <h1 className="text-center text-white">
              You won't be disappointed
            </h1>
          </div>
        </Orientation>
      </DeviceOrientation>
    </>
  );
};

export default Annoncement;
