import bike from "../assets/bike.jpg";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "react-vertical-timeline-component/style.min.css";

function Story() {
  const styleicon = {
    background: "rgb(64, 121, 228)",
    color: "#fff",
    border: "5px solid #F39F18",
  };

  

      const stylecontent={
        background: '#eff7ff', 
        color: 'rgb(64, 121, 228) '
      }

      const contentArrowStyle={
        borderRight: '7px solid  #fff' 

      }

      
    const tabs = [
        {text: "Première rencontre, sans le savoir, lors du kick-off de l’atelier musique de chambre à Ginette.", img: bike,date:"1er octobre 2012"}, 
        {text: "Deuxième rencontre, la vraie, en section Basket, le jour où Etienne candidate et obtient le joyeux poste de “responsable bar d’étage”. Notre amitié se renforce au cours du printemps suivant, le “tronc commun” de Polytechnique dont le point d’orgue est le défilé du 14 juillet.", img: bike,date:"10 avril 2014"},
        {text: "Le début de la grande aventure ! Avec quelques aller-retour Paris-Barcelone pendant l’été puisque Etienne commence sa carrière de SW engineer en France tandis qu’Anne-Claire essaie d’optimiser des lignes de production de sac à main en Catalogne. ", img: bike,date:"10 juin 2015"},
        {text: "La conquête de l’Ouest commence avec la côte Est du continent nord-américain. Boston et Montréal sont propices au stage de recherche et finalement pas si loin, notre “I-94 Travel History” s’allonge d’une bonne dizaine de lignes. ", img: bike,date:"1er mars 2016"},
        {text: "California, here we are ! Six mois de césure, la découverte d’Oakland et de San Francisco, premier aperçu de la Silicon Valley, un début plutôt prometteur de la cohabitation… promis, on y retournera !", img: bike,date:"1er octobre 2016"},
        {text: "Sur la ligne de départ pour le master aux Etats-Unis il y avait Anne-Claire et Etienne, ce sera finalement un départ en solo pour New-York de Etienne qui rapidement se fait un nom au traiteur italien Di Palo de Little East Village et au Data Center de NYU. ", img: bike,date:"29 août 2017"},
        {text: "Après un an et demi d’une vie étudiante plus ou moins intense en fonction de la proximité au pôle Nord, un nouvel horizon se dessine: Etienne signe un full-time job au sud de San Francisco ! Les premiers mois seront cependant au siège indien de la boîte à Bengalore … à quelques heures de Hyderabad où Anne-Claire fait justement des expériences pour sa recherche du MIT!", img: bike,date:"25 décembre 2018"},
        {text: "C’est au tour de Anne-Claire de graduate et s’envoler pour San Francisco! Ca y est, le retour. Grand remue-ménage professionnel, Etienne rejoint Samsara, numéro 1 du swag, et Anne-Claire travaille sur des voitures autonomes dans l’espoir d’éviter le passage du permis français.", img: bike,date:"31 août 2019"},
        {text: "Lock down du Covid, a.k.a apprentissage du kitesurf. On apprend aussi à connaître mieux San Francisco, la Californie, les Etats-Unis, avec des voyages en Alaska, au Texas, au Colorado.", img: bike,date:"10 mars 2020"},
        {text: "C’est décidé, en Octobre, ce sera le grand retour en France ! On a le coeur lourd de quitter notre vie américaine, mais avons hâte de retrouver famille et amis. C’est une nouvelle étape sur tous les plans !", img: bike,date:"10 juin 2021"},
        {text: "Création officielle de Beavr. Etienne réalise son rêve d’entrepreneur sans salaire, tandis que Anne-Claire se tourne vers les ordinateurs quantiques. Début de la vie parisienne, pas de tout repos.", img: bike,date:"10 janvier 2022"},
        {text: "La décision est prise lors d’une randonnée Ubaye de nous marier !", img: bike,date:"1er août 2022"}
        ]
    return (
        <div className=" bg-blue/20">
            <div >
            <VerticalTimeline layout="1-column-left" lineColor="#F39F18" >
            {tabs.map((tab, idx) => <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={stylecontent}
                contentArrowStyle={contentArrowStyle}
                date={tab.date}
                iconStyle={styleicon}
                textClassName='font-default'
            >

                <div className={`space-y-4 lg:flex ${idx % 2 === 0 && "lg:flex-row-reverse"}`}>
                    <div className="lg:px-4 lg:w-1/2" id="top">
                            <p className=" text-justify">
                            {tab.text}
                        </p>
                    </div>  
                    <div className="lg:w-1/2 h-full lg:px-4" id="bottom">
                        <img src={tab.img} alt="AC et E à vélo" className=" h-full" />
                    </div>
                </div>
              <div className="text-center sm:text-left"></div>
            </VerticalTimelineElement>
          )}
        </VerticalTimeline>
      </div>
    </div>
  );
}

export default Story;
